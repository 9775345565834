import { EventRouter } from "../../common/events"
import { preventMultiClicks } from "../../common/eventsUtil"
import { loadRoomRequest } from "../../common/fullvideolib/userActionEvents"
import { dom } from "../../common/tsxrender/dom"
import { RoomCard } from "../components/roomlist/roomCard"
import { RoomList } from "../components/roomlist/roomList"
import { getRoomImageURL } from "../roomList"
import { hideTagTableToolTip, showTagTableToolTip } from "./tagTableEvents"
import type { IRoomCardProps } from "../components/roomlist/roomCard"
import type { IRoomClickDetails, IRoomListProps } from "../components/roomlist/roomList"

export class TagTableThumbnailRoomCard extends RoomCard {
    protected createElement(props: IRoomCardProps): HTMLLIElement {
        const roomInfo = props.roomInfo

        return <li
            className="tag-table-room-card"
            onClick={e => this.fireRoomClick(e)}
            onMouseOver={(e: MouseEvent) => {
                showTagTableToolTip.fire({
                    ...props,
                    roomImage: this.img,
                })
            }}
            onMouseOut={(e: MouseEvent) => {
                hideTagTableToolTip.fire({ event: e, forceHide: false })
            }}
        >
            {preventMultiClicks(<a
                className="room_link"
                data-roomlink="true"
                href={`/${roomInfo.room}/`}
                data-room={roomInfo.room}
            >
                <img
                    ref={(el: HTMLImageElement) => { this.img = el }}
                    data-room-nav
                    className="room_thumb"
                    src={getRoomImageURL(roomInfo.room)}
                    loading="lazy"
                />
            </a>)}
        </li>
    }
}

export class TagTableRoomList extends RoomList<TagTableThumbnailRoomCard> {
    protected initUI(props: IRoomListProps): void {
        this.roomListProps = props

        this.roomClickEvent = new EventRouter<IRoomClickDetails>("roomClick")
        this.roomClickEvent.listen(evt => {
            loadRoomRequest.fire(evt.roomInfo.room)
        })

        this.roomBeforeClickEvent = new EventRouter<IRoomClickDetails>("roomBeforeClick")
        this.roomBeforeClickEvent.listen(evt => {
            this.saveRoomListSourceCookie(evt, props)
        })

        this.updateState()
    }

    protected createRoomCard(roomProps: IRoomCardProps): TagTableThumbnailRoomCard {
        return new TagTableThumbnailRoomCard(roomProps)
    }

    protected createElement(): HTMLUListElement {
        return <ul className="tag-table-roomlist" />
    }

    // Because we don't show follow stars in the tag table tooltips, these two methods are unneeded.
    // Also, since the tags page builds a RoomList for each tag in the table, the pageshow listener
    // in bindCachedFollowStarFix() drastically exceeds our 42-listener limit if it's applied here.
    protected updateFollowStars(): void { }

    protected bindCachedFollowStarFix(): void { }

    protected unbindCachedFollowStarFix(): void { }
}
