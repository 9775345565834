import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb, normalizeResource } from "../../common/api"
import { HTMLComponent } from "../../common/defui/htmlComponent"
import { Gender, GendersSymbolToNameMap, getCurrentGender } from "../../common/genders"
import { addPageAction } from "../../common/newrelic"
import { i18n } from "../../common/translation"
import { dom, Fragment } from "../../common/tsxrender/dom"
import { buildQueryString, parseQueryString, replaceUrlParams } from "../../common/urlUtil"
import { Pagination } from "../components/pagination/pagination"
import { parseTagTableData } from "./hashtagData"
import { SortableTagTableHeader } from "./sortableTagTableHeader"
import { hideTagTableToolTip, showTagTableToolTip, updateTagTableGender, updateTagTableSortField } from "./tagTableEvents"
import { PlaceHolderTagTableRow, TagTableRow } from "./tagTableRow"
import { TagToolTip } from "./tagTableToolTip"
import type { ITagTableData } from "./hashtagData"
import type { ISortableTagFieldState } from "./sortableTagField"
import type { IHideTagTableToolTip } from "./tagTableEvents"
import type { ITagTableToolTipInfo } from "./tagTableToolTip"

export const BASE_TAGS_URL = "/tags/"
export const DEFAULT_THUMBNAILS_LIMIT = 12

export interface IHashtagSortInfo {
    field: string
    asc: boolean
}

export interface ITagTableUpdateInfo extends IHashtagSortInfo {
    /*
    Use toggleSortField to indicate if we should be using the received infomation for sort fields as is, or
    if additional logic to correct the sorting options
    */
    toggleSortField: boolean
    page: string
    gender: Gender
}

interface ITagTableProps {showLocation: boolean}

interface ITagTableState {
    activeField: string
    activeFieldSortAsc: boolean
    gender: Gender
    page: number
    tableData?: ITagTableData
}

interface ISortableFieldDetails {
    fieldName: string
    text: string
}

interface ITagTableDataEndpointParams {
    sort: string
    page: number
    gender: Gender
    limit: number
}

export const TAG_SORT_COOKIE_NAME = "tgsrt"

export class SortableTagTable extends HTMLComponent<HTMLDivElement, ITagTableProps, ITagTableState> {
    private pagination: Pagination
    private props: ITagTableProps
    private tagToolTip: TagToolTip
    private tagTableBody: HTMLDivElement
    private tagTableHeader: SortableTagTableHeader
    private updateInProgress = false
    private initialLoad = true  // Used to correct window location on initial load

    public static readonly HASHTAG_SORT_FIELD_MAP = new Map<string, ISortableFieldDetails>([
        ["ht", { fieldName: "hashtag", text: i18n.hashtag }],
        ["vc", { fieldName: "viewers", text: i18n.viewers }],
        ["rc", { fieldName: "rooms", text: i18n.rooms }],
    ])

    private static readonly TAG_PAGE_SIZE = 100

    protected createElement(props: ITagTableProps): HTMLDivElement {
        this.props = props

        return <div id="tag-table-container">
            <h2
                className="no-tags-message"
                bind={{
                    display: () =>
                        this.state.tableData === undefined ||
                            this.state.tableData?.total === 0 ?
                            "block" : "none",
                }}
            >No tags to display.</h2>
            <div
                bind={{
                    display: () =>
                        this.state.tableData !== undefined &&
                            this.state.tableData.total !== 0 ?
                            "inline-block" : "none",
                }}
                id="tag_table"
            >
                <SortableTagTableHeader
                    sortableTagTable={this}
                    classRef={(c: SortableTagTableHeader) => {
                        this.tagTableHeader = c
                    }}
                />
                <div
                    className="tag-table-body"
                    ref={(el: HTMLDivElement) => { this.tagTableBody = el }}
                >
                    {this.createPlaceHolderRows()}
                </div>
            </div>
            <div
                id="roomlist_pagination"
                bind={{
                    display: () =>
                        this.state.tableData !== undefined &&
                            this.state.tableData?.total !== 0 ?
                            "flex" : "none",
                }}
            >
                <Pagination
                    classRef={(el: Pagination) => {
                        this.pagination = el
                    }}
                    onPageChange={() => {
                        void this.load({
                            field: this.state.activeField,
                            asc: this.state.activeFieldSortAsc,
                            page: `${this.pagination.currentPage}`,
                            gender: this.state.gender,
                            toggleSortField: false,
                        })
                        this.element.scrollIntoView()
                    }}
                    itemsPerPage={SortableTagTable.TAG_PAGE_SIZE}
                    reloadOnPageChange={false}
                />
            </div>
        </div>
    }

    constructor(props: ITagTableProps) {
        super(props)

        this.state.activeField = ""
        this.state.activeFieldSortAsc = false
        this.state.gender = Gender.All

        this.bindUpdateTagTableListeners()
        this.bindTagToolTip()

        this.loadTableFromExistingParams()

        replaceUrlParams(new Map([["g", ""]]))  // Location should have been updated with single gendered path
    }

    public loadTableFromExistingParams(updateState = true): void {
        const queryParams = parseQueryString(window.location.search)
        const sortQueryParam = queryParams["sort"]
        const page = queryParams["page"] ?? "1"
        const gender = getCurrentGender()

        let sortField = "", sortAsc = false

        if (sortQueryParam !== undefined) {
            sortAsc = !sortQueryParam.startsWith("-")
            sortField = sortAsc ? sortQueryParam : sortQueryParam.slice(1)
        }

        void this.load({
            field: sortField,
            asc: sortAsc,
            page: page,
            gender: gender,
            toggleSortField: false,
        }, updateState)
    }

    private clearTagTableRows(): void {
        while (this.tagTableBody.lastChild !== null) {
            this.tagTableBody.removeChild(this.tagTableBody.lastChild)
        }
    }

    updateState(): void {
        super.updateState()

        if (this.state.tableData !== undefined && this.state.tableData.total !== 0) {
            this.clearTagTableRows()
            for (let index = 0; index < this.state.tableData.hashtags.length; index++) {
                const hashtagData = this.state.tableData.hashtags[index]
                this.addChild(
                    new TagTableRow({
                        followed: hashtagData.followed,
                        gender: this.state.gender,
                        hashtagIndex: index + 1,
                        hashtag: hashtagData.hashtag,
                        roomCount: hashtagData.roomCount,
                        showLocation: this.props.showLocation,
                        thumbnailLimit: DEFAULT_THUMBNAILS_LIMIT,
                        topRooms: hashtagData.topRooms,
                        viewerCount: hashtagData.viewerCount,
                    }),
                    this.tagTableBody,
                )
            }
        }
    }

    private setActiveSortableField(fieldKey: string, sortFieldState: ISortableTagFieldState): void {
        this.tagTableHeader.resetFields()

        const activeSortableField = this.tagTableHeader.getSortableFieldByKey(fieldKey)

        if (activeSortableField !== undefined) {
            activeSortableField.setState({
                isAscending: sortFieldState.isAscending,
                isActive: sortFieldState.isActive,
            })
        }
    }

    private toggleSortField(hashtagUpdateParams: ITagTableUpdateInfo): IHashtagSortInfo {
        /*
        We expect to get the current sort field key as well the sort direction for is ascending or descending.
        The following logic helps to determine if we should toggle the current field based on their sort
        direction or remove the sorting option(resume sorting by default) if the same sort field was selected and
        was set to ascending.
        */
        let activeFieldName

        const isPreviouslySelectedField = this.state.activeField === hashtagUpdateParams.field
        const isAscending = isPreviouslySelectedField && !hashtagUpdateParams.asc
        const sortByDefault = isPreviouslySelectedField && hashtagUpdateParams.asc

        if (!sortByDefault) {
            activeFieldName = hashtagUpdateParams.field
        } else {
            activeFieldName = ""
        }

        this.setActiveSortableField(activeFieldName, { isAscending: isAscending, isActive: !sortByDefault })

        addPageAction("TagPageArrowClicked", {
            "ArrowName": SortableTagTable.HASHTAG_SORT_FIELD_MAP.get(hashtagUpdateParams.field)?.fieldName,
            "ArrowDirection": sortByDefault ? "default" : isAscending ? "ascending" : "descending",
        })

        return {
            field: activeFieldName,
            asc: isAscending,
        }
    }


    async load(hashtagUpdateParams: ITagTableUpdateInfo, updateState = true): Promise<void> {
        if (this.updateInProgress) {
            return
        }

        this.pagination.hideElement()
        this.updateInProgress = true
        this.clearTagTableRows()
        this.tagTableBody.appendChild(this.createPlaceHolderRows())

        let sortFieldParams: IHashtagSortInfo = {
            field: hashtagUpdateParams.field,
            asc: hashtagUpdateParams.asc,
        }

        if (hashtagUpdateParams.toggleSortField) {
            sortFieldParams = this.toggleSortField(hashtagUpdateParams)
        } else {
            this.setActiveSortableField(
                hashtagUpdateParams.field,
                { isAscending: hashtagUpdateParams.asc, isActive: true },
            )
        }

        const genderChanged = this.state.gender !== hashtagUpdateParams.gender
        const page = parseInt(hashtagUpdateParams.page)
        const pageChanged = this.state.page !== page

        const params: ITagTableDataEndpointParams = {
            sort: sortFieldParams.field !== "" ? `${sortFieldParams.asc ? "" : "-"}${sortFieldParams.field}` : "",
            page: page,
            gender: hashtagUpdateParams.gender,
            limit: SortableTagTable.TAG_PAGE_SIZE,
        }

        if (updateState) {
            this.updateUrlWithGenderAndParams(params, !this.initialLoad && (genderChanged || pageChanged))
        }

        const tagTableData = await this.handleTagTableResponse(page, params)

        this.pagination.setState({ totalItems: tagTableData.total, currentPage: page })
        this.pagination.showElement("flex")

        this.setState({
            tableData: tagTableData,
            activeField: sortFieldParams.field,
            activeFieldSortAsc: sortFieldParams.asc,
            page: page,
            gender: hashtagUpdateParams.gender,
        })

        this.updateInProgress = false
        this.initialLoad = false
    }

    private async handleTagTableResponse(page: number, params: ITagTableDataEndpointParams): Promise<ITagTableData> {
        /**
         * Additional handling depending on the results of the page request. If we find that on the initial attempt to get data for a particular page that
         * it is empty, and that the page requested exceeds the total current possible pages, we will attempt to re-fetch results for the first page
         * instead.
         */
        let tagTableData = await this.getApiResponse(params)

        if (
            tagTableData.hashtags.length === 0 &&
            page > Pagination.calculateMaxPages(tagTableData.total, SortableTagTable.TAG_PAGE_SIZE)
        ) {
            this.pagination.currentPage = 1
            replaceUrlParams(new Map([["page", ""]]))
            tagTableData = await this.getApiResponse({ ...params, page: 1 })
        }

        return tagTableData
    }

    private async getApiResponse(params: ITagTableDataEndpointParams): Promise<ITagTableData> {
        const paramMap = SortableTagTable.mapTagTableDataParamsToObject(params)
        const xhr = await getCb(`api/ts/hashtags/tag-table-data/?${buildQueryString(paramMap)}`)
        const result = new ArgJSONMap(xhr.responseText)
        return parseTagTableData(result)
    }

    private bindUpdateTagTableListeners(): void {
        updateTagTableGender.listen((gender) => {
            void this.load({
                field: this.state.activeField,
                asc: this.state.activeFieldSortAsc,
                page: "1", // We reset pagination in the case of a gender update
                gender: gender,
                toggleSortField: false,
            })
        })
    }

    private bindTagToolTip(): void {
        this.tagToolTip = new TagToolTip({ showLocation: this.props.showLocation })
        this.addChild(this.tagToolTip)

        showTagTableToolTip.listen((toolTipInfo: ITagTableToolTipInfo) => {
            let tagTipOffset: number

            const roomImageDOMREct = toolTipInfo.roomImage.getBoundingClientRect()
            const roomImageOffset = {
                top: Math.round(roomImageDOMREct.top + window.pageYOffset),
                left: Math.round(roomImageDOMREct.left + window.pageXOffset),
            }

            // Height is set with inline styling depending on Simple Room Card test variant
            const toolTipStyles = getComputedStyle(this.tagToolTip.element)
            const tagToolTipHeight = parseInt(toolTipStyles.height)
            const TagToolTipWidth = parseInt(toolTipStyles.width)

            if (window.innerHeight - roomImageOffset.top + window.pageYOffset < 300) {
                tagTipOffset = -16 - tagToolTipHeight
            } else {
                tagTipOffset = roomImageDOMREct.height + 8
            }

            this.tagToolTip.element.style.left = `${roomImageOffset.left + roomImageDOMREct.width / 2 - TagToolTipWidth / 2}px`
            this.tagToolTip.element.style.top = `${roomImageOffset.top + tagTipOffset}px`
            this.tagToolTip.setState(toolTipInfo)
            this.tagToolTip.showElement()
        })

        hideTagTableToolTip.listen((info: IHideTagTableToolTip) => {
            let isWithinToolTip = false

            if (info.event !== undefined) {
                isWithinToolTip = 
                    info.event.relatedTarget === this.tagToolTip.element ||
                    this.tagToolTip.element.contains(info.event.relatedTarget as HTMLElement)
                // We only want to hide the tool tip if we are no longer hovering over the room thumbnail and the tooltip
            }

            if (!isWithinToolTip || info.forceHide === true) {
                this.tagToolTip.hideElement()
            }
        })
    }

    private updateUrlWithGenderAndParams(params: ITagTableDataEndpointParams, pushState: boolean): void {
        let baseUrl = BASE_TAGS_URL
        if (params.gender !== Gender.All) {
            baseUrl = `${BASE_TAGS_URL}${GendersSymbolToNameMap.get(params.gender)}/`
        }

        const searchParams = parseQueryString(window.location.search)

        searchParams["page"] = `${params.page}`

        if (params.page !== 1) {
            searchParams["page"] = `${params.page}`
        } else {
            delete searchParams["page"]
        }

        if (params.sort !== "") {
            searchParams["sort"] = params.sort
        } else {
            delete searchParams["sort"]
        }

        const queryStr = buildQueryString(searchParams)
        const updatedLocation = normalizeResource(`${baseUrl}${queryStr ? `?${queryStr}` : ""}`)
        if (pushState) {
            window.history.pushState(undefined, "", updatedLocation)
        } else {
            window.history.replaceState(undefined, "", updatedLocation)
        }

        updateTagTableSortField.fire()
    }

    public static mapTagTableDataParamsToObject(params: ITagTableDataEndpointParams): Record<string, string> {
        return {
            "sort": params.sort,
            "page": `${params.page}`,
            "g": params.gender,
            "limit": `${params.limit}`,
        }
    }

    private createPlaceHolderRows(): DocumentFragment {
        return <Fragment>
            {Array(10).fill(0).map(() => {
                return <PlaceHolderTagTableRow />
            })}
        </Fragment>
    }

    get page(): number {
        return this.state.page
    }

    get gender(): Gender {
        return this.state.gender
    }
}
