import { addCountryFlags } from "../cb/components/roomlist/countryFlagUtils"
import { bindAllDismissibleNotices } from "../cb/dismissibleNotice"
import { addGenderToSubjectLinks, RoomReload } from "../cb/roomList"
import { preventMultiClicks } from "../common/eventsUtil"
import { getMobileRoomListSortButton } from "../common/mobilelib/mobileSortForm"
import { bindTagListPageAction } from "../common/mobilelib/mobileTagPageActions"
import { BaseEntryPoint, exportEntryPoint } from "./base"

exportEntryPoint(class Page extends BaseEntryPoint {
    protected initMobile(): void {
        super.initMobile()
        bindAllDismissibleNotices()
        bindTagListPageAction()
        addGenderToSubjectLinks(document.body)
        addCountryFlags()
        RoomReload.getInstance().addOnLoadHandler(addCountryFlags)

        const mobileSortContainer = document.getElementById("mobile-sort-container")
        if (mobileSortContainer !== null) {
            const mobileSortContainer = document.querySelector<HTMLElement>("#mobile-sort-container")
            if (mobileSortContainer !== null) {
                mobileSortContainer.appendChild(getMobileRoomListSortButton())
            }
        }

        // Debounce mobile roomcard room links to help prevent duplicate BroadcasterLinkSource events
        this.debounceTemplateRoomCardLinks()
        RoomReload.getInstance().addOnLoadHandler(this.debounceTemplateRoomCardLinks)
    }

    private debounceTemplateRoomCardLinks(): void {
        document.querySelectorAll(".room_list_room a:not([href^='/tag/'])").forEach((el: HTMLAnchorElement) => {
            // Pass a longer timeout than default for mobile, since mobile connections are often slower
            preventMultiClicks(el, true, 6000)
        })
    }
})
