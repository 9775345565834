import { HTMLComponent } from "../../common/defui/htmlComponent"
import { Gender, GendersSymbolToNameMap } from "../../common/genders"
import { dom } from "../../common/tsxrender/dom"
import { RoomListSource } from "../roomList"
import { TagTableRoomList } from "./tagTableRoomList"
import type { IHashtagData } from "./hashtagData"

interface TagTableProps extends IHashtagData {
    gender: Gender
    hashtagIndex: number
    showLocation: boolean
    thumbnailLimit: number
}

export class TagTableRow extends HTMLComponent<HTMLDivElement, TagTableProps> {
    protected createElement(props: TagTableProps): HTMLDivElement {
        return <div className="tag_row">
            <span className="tag" data-testid="hashtag-list-item">
                <a
                    href={hashtagUrl(props.hashtag, props.gender)}
                    title={props.hashtag}
                >
                    #{props.hashtag}
                </a>
            </span>
            <span className="viewers">{props.viewerCount}</span>
            <span className="rooms">{props.roomCount}</span>
            <span className="room_thumbnails">
                <TagTableRoomList
                    roomListSource={RoomListSource.TagPage}
                    animate={false}
                    rooms={() => props.topRooms.slice(0, props.thumbnailLimit)}
                    sourceIndex={() => { return props.hashtagIndex }}
                    hashtag={() => props.hashtag}
                    showLocation={props.showLocation}
                />
            </span>
        </div>
    }
}


export class PlaceHolderTagTableRow extends HTMLComponent<HTMLDivElement> {
    protected createElement(props: object): HTMLDivElement {
        return <div className="tag_row placeholder">
            <span className="tag"><div>&nbsp;</div></span>
            <span className="viewers"><div>&nbsp;</div></span>
            <span className="rooms"><div>&nbsp;</div></span>
            <span className="room_thumbnails">
                <ul className="tag-table-roomlist">
                    {Array(6).fill(0).map(() => {
                        return <li className="tag-table-room-card room_thumb" />
                    })}
                </ul>
            </span>
        </div>
    }
}

function hashtagUrl(hashtag: string, gender: Gender): string {
    if (hashtag === "") {
        return "/tag/"
    }
    return `/tag/${hashtag}/${gender !== Gender.All ? `${GendersSymbolToNameMap.get(gender)}/` : ""}`
}

