import { HTMLComponent } from "../../common/defui/htmlComponent"
import { dom } from "../../common/tsxrender/dom"
import { RoomCard } from "../components/roomlist/roomCard"
import { applyShowLocationClass } from "../components/roomlist/roomList"
import { getRoomImageURL } from "../roomList"
import { hideTagTableToolTip } from "./tagTableEvents"
import type { IRoomCardProps } from "../components/roomlist/roomCard"

export interface ITagTableToolTipInfo extends IRoomCardProps {roomImage: HTMLImageElement}

class TagTableDetailedRoomCard extends RoomCard {
    public setImageStreamer(): void { }

    protected createFollowStar(props: IRoomCardProps): void { }

    protected initUI(props: IRoomCardProps): void {
        super.initUI(props)
        this.img.loading = "eager"
        this.element.style.margin = "0"
    }
}

interface ITagToolTipProps {showLocation: boolean}

type ITagTableToolTipState = IRoomCardProps
export class TagToolTip extends HTMLComponent<HTMLDivElement, ITagToolTipProps, ITagTableToolTipState> {
    private props: ITagToolTipProps
    private roomCard: TagTableDetailedRoomCard
    private roomList: HTMLUListElement

    protected createElement(props: ITagToolTipProps): HTMLDivElement {
        this.props = props
        return <div
            id="tag_tip"
            style={{ height: "175px" }}
            onMouseOut={(e: MouseEvent) => {
                hideTagTableToolTip.fire({ event: e, forceHide: false })
            }}
            onClick={() => hideTagTableToolTip.fire({ forceHide: true })}
        >
            <ul className="list" ref={(el: HTMLUListElement) => { this.roomList = el }} />
        </div>
    }

    protected initUI(props: ITagToolTipProps): void {
        super.initUI()
        applyShowLocationClass(this.roomList, props.showLocation)
    }

    updateState(): void {
        super.updateState()

        if (this.roomCard !== undefined) {
            this.removeChild(this.roomCard)
        }

        const toolTipRoomInfo = {
            ...this.state.roomInfo,
            img: getRoomImageURL(this.state.roomInfo.room),
            location: this.props.showLocation ? this.state.roomInfo.location : undefined,  // We always get location data from the endpoint, but will follow user's saved selection on showLocation
        }

        this.roomCard = new TagTableDetailedRoomCard({
            ...this.state,
            roomInfo: toolTipRoomInfo,

        })
        this.addChild(this.roomCard, this.roomList)
    }
}
