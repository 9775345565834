
import { registerScrollStartEndDebouncer } from "../../cb/ui/scrollUtil"
import { addPageAction } from "../newrelic"

export function bindTagListPageAction(): void {
    const container = document.querySelector<HTMLDivElement>("#mobile-hashtag-ticker .scroll-container")
    const scrolledHandler = (isStart: boolean): void => {
        if (isStart) {
            addPageAction("HomepageTagScrolled")
        }
    }

    if (container !== null) {
        registerScrollStartEndDebouncer(container, scrolledHandler)
    }
}

export function bindTagCarouselPageAction(): void {
    const containers = document.querySelectorAll<HTMLDivElement>(".scroll-rooms")

    containers.forEach((element, index) => {
        const handler = handlerFactory(element, index)
        registerScrollStartEndDebouncer(element, handler)
    })

    function handlerFactory(element: HTMLElement, tagRank: number): (isStart: boolean) => void {
        return (isStart: boolean): void => {
            if (!isStart) {
                addPageAction("TagRoomScrolled", {
                    "tagName": `${element.dataset["tag"]}`,
                    "tagOffset": `${calculateApproximateOffset(element.scrollLeft)}`,
                    "tagRank": `${tagRank + 1}`,
                })
            }
        }
    }

    function calculateApproximateOffset(scrollPosition: number): number {
        const THUMBNAIL_SIZE = 87 // size in pixels on display, including left margin
        return Math.round(scrollPosition / THUMBNAIL_SIZE)
    }
}
