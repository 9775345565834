import { EventRouter } from "../../common/events"
import type { ITagTableToolTipInfo } from "./tagTableToolTip"
import type { Gender } from "../../common/genders"

export interface IHideTagTableToolTip {
    forceHide?: boolean
    event?: MouseEvent
}

export const updateTagTableGender = new EventRouter<Gender>("updateTagTableGenderEvent")

export const showTagTableToolTip = new EventRouter<ITagTableToolTipInfo>("showTagTableToolTip")

export const hideTagTableToolTip = new EventRouter<IHideTagTableToolTip>("hideTagTableToolTip")

export const updateTagTableSortField = new EventRouter<void>("updateTagTableSortFieldEvent")
