import { HTMLComponent } from "../../common/defui/htmlComponent"
import { dom } from "../../common/tsxrender/dom"

export interface ISortableTagFieldState {
    isAscending: boolean
    isActive: boolean
}

interface ISortableTagFieldProps extends ISortableTagFieldState {
    sortFieldParam: string
    text: string
    onSortChange: () => void
}

export class SortableTagField extends HTMLComponent<HTMLSpanElement, ISortableTagFieldProps, ISortableTagFieldState> {
    private _sortFieldParam: string

    constructor(props: ISortableTagFieldProps) {
        super(props)
        this._sortFieldParam = props.sortFieldParam
    }

    get sortFieldParam(): string {
        return this._sortFieldParam
    }

    get isAscending(): boolean {
        return this.state.isAscending
    }

    protected createElement(props: ISortableTagFieldProps): HTMLSpanElement {
        return <span
            className="sortable"
            data-param={props.sortFieldParam}
            onClick={props.onSortChange}
            data-testid={props.sortFieldParam}
        >
            {props.text}
            <div
                bind={{
                    className: () =>
                        `sortable-arrow ${this.state.isAscending ?
                            "ascending" : "descending"}${
                            this.state.isActive ? " active" : ""}`,
                }}
            />
        </span>
    }
}
