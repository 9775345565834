import { parseIRoomInfo } from "../components/roomlist/roomCard"
import type { IRoomInfo } from "../components/roomlist/IRoomInfo"
import type { ArgJSONMap } from "@multimediallc/web-utils"

export interface IHashtagData {
    hashtag: string,
    viewerCount: number,
    roomCount: number,
    topRooms: IRoomInfo[],
    followed: boolean,
}

export interface ITagTableData {
    hashtags: IHashtagData[],
    total: number,
}

export function parseTagTableData(data: ArgJSONMap): ITagTableData {
    const hashtagStringData = data.getList("hashtags")

    return {
        hashtags: hashtagStringData?.map(data =>
            parseHashtagData(data),
        ) ?? [],
        total: data.getNumber("total"),
    }
}

export function parseHashtagData(rawRoomInfo: ArgJSONMap): IHashtagData {
    return {
        followed: rawRoomInfo.getBoolean("followed", false, false),
        hashtag: rawRoomInfo.getString("hashtag"),
        viewerCount: rawRoomInfo.getNumber("viewer_count"),
        roomCount: rawRoomInfo.getNumber("room_count"),
        topRooms: rawRoomInfo.getList("top_rooms")?.map((room) => {
            return parseIRoomInfo(room)
        }) ?? [],
    }
}
