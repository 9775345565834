import { HTMLComponent } from "../../common/defui/htmlComponent"
import { dom } from "../../common/tsxrender/dom"
import { SortableTagField } from "./sortableTagField"
import { SortableTagTable } from "./sortableTagTable"

interface ISortableTagHeaderProps {sortableTagTable: SortableTagTable}

export class SortableTagTableHeader extends HTMLComponent<HTMLDivElement, ISortableTagHeaderProps> {
    private _sortableFields: Map<string, SortableTagField>
    private props: ISortableTagHeaderProps

    protected createElement(props: ISortableTagHeaderProps): HTMLDivElement {
        this.props = props
        this._sortableFields = new Map<string, SortableTagField>()

        return <div className="headers">
            <div className="tag_row">
                {Array.from(SortableTagTable.HASHTAG_SORT_FIELD_MAP.keys(), (key: string) => {
                    return <SortableTagField
                        classRef={(el: SortableTagField) => {
                            this._sortableFields.set(key, el)
                        }
                        }
                        onSortChange={() => this.onSortChange(key)}
                        sortFieldParam={key}
                        text={SortableTagTable.HASHTAG_SORT_FIELD_MAP.get(key)?.text ?? ""}
                        isActive={false} isAscending={true}
                    />
                })}
                <span className="room_thumbnails">Top Rooms</span>
            </div>
        </div>
    }

    protected initUI(props: ISortableTagHeaderProps): void {
        super.initUI(props)
        this.getSortableFieldByKey("ht")?.element.classList.add("tag")
    }

    public resetFields(): void {
        this._sortableFields.forEach((sortableTagField) => {
            sortableTagField.setState({ isAscending: false, isActive: false })
        })
    }

    public getSortableFieldByKey(key: string): SortableTagField | undefined {
        return this._sortableFields.get(key)
    }

    private onSortChange(key: string): void {
        const sortableField = this._sortableFields.get(key)
        if (sortableField !== undefined) {
            void this.props.sortableTagTable.load({
                field: sortableField.sortFieldParam,
                asc: sortableField.isAscending,
                page: `${this.props.sortableTagTable.page}`,
                gender: this.props.sortableTagTable.gender,
                toggleSortField: true,
            })
        }
    }
}
