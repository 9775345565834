import { getCookieOrUndefined, setCookieWithExpiration } from "@multimediallc/web-utils/storage"
import { TAG_SORT_COOKIE_NAME } from "../../cb/hashtags/sortableTagTable"
import { loadRooms } from "../../cb/roomList"
import { Component } from "../defui/component"
import { isLanguageSubdomainActive } from "../featureFlagUtil"
import { addPageAction } from "../newrelic"
import { dom } from "../tsxrender/dom"
import { replaceUrlParams } from "../urlUtil"
import { screenOrientationChanged } from "./windowOrientation"

type SortOption = [value: string, name: string]

class RoomListMobileSortForm extends Component {
    private button: HTMLButtonElement
    private selectFormElement: HTMLSelectElement

    protected readonly sortOptions: SortOption[] = [
        ["", "Popular (Default)"],
        ["rv", "Viewers (Low to High)"],
        ["rand", "Randomized"],
    ]
    protected readonly cookieName: string | undefined = "rlsrt"
    protected readonly clickPageAction: string = "CamSortIconClicked"
    protected readonly changePageAction: string = "CamSortIconChanged"

    protected reload(): void {
        loadRooms()
    }

    init(): void {
        this.initElement()
        this.applySortParam()

        screenOrientationChanged.listen(() => {
            this.selectFormElement.disabled = true
            this.selectFormElement.blur()
            window.setTimeout(()=> {
                this.selectFormElement.disabled = false
            }, 100)
        })
    }

    private parseSortParam(sortParam: string | null): string {
        const validSortOptions: string[] = this.sortOptions.map(option => option[0])

        let parsedSortParam = ""
        if (sortParam !== null && validSortOptions.includes(parsedSortParam)) {
            parsedSortParam = sortParam
        } else if (this.cookieName !== undefined) {
            const sortParamFromCookie = getCookieOrUndefined(this.cookieName)
            if (sortParamFromCookie !== undefined && validSortOptions.includes(sortParamFromCookie)) {
                parsedSortParam = sortParamFromCookie
            }
        }
        return parsedSortParam
    }

    private applySortParam(): void {
        const sortParam = new URLSearchParams(window.location.search).get("sort")
        const parsedSortParam = this.parseSortParam(sortParam)

        if (parsedSortParam !== (sortParam ?? "")) {
            this.setRoomListSortCookie(parsedSortParam)
            replaceUrlParams(new Map([["sort", parsedSortParam]]))
            this.reload()
        }

        const isSortActive = parsedSortParam !== ""
        if (isSortActive) {
            this.button.classList.add("active")
        }

        const selectedOption = this.element.querySelector(`option[value="${parsedSortParam}"]`)
        if (selectedOption !== null) {
            // eslint-disable-next-line @multimediallc/no-set-attribute
            selectedOption.setAttribute("selected", "selected")
        }
    }

    private setRoomListSortCookie(value: string): void {
        if (this.cookieName !== undefined) {
            setCookieWithExpiration(this.cookieName, value, { days: 3 }, "/", isLanguageSubdomainActive())
        }
    }

    private initElement() {
        let currentChoice = ""
        const handleClick = (e: Event) => {
            addPageAction(this.clickPageAction)
            currentChoice = (e.target as HTMLSelectElement).value
        }

        const handleChange = (e: Event) => {
            const selectElement = e.target as HTMLSelectElement
            const selectValue = selectElement.value
            replaceUrlParams(new Map([["sort", selectValue]]))
            this.setRoomListSortCookie(selectValue)
            if (selectValue === "") {
                this.button.classList.remove("active")
            } else {
                this.button.classList.add("active")
            }
            addPageAction(this.changePageAction, {
                "option_change_from": currentChoice,
                "option_change_to": selectValue,
            })

            this.reload()
        }

        this.element = 
            <div id="mobile-sort-form-container">
                <button 
                    className="mobile-sorting-button"
                    ref={(e: HTMLButtonElement) => this.button = e}
                >
                    Sort Options
                </button>
                <form id="mobile-sorting-form">
                    <select 
                        id="mobile-sorting-options" 
                        ref={(e: HTMLSelectElement) => this.selectFormElement = e} 
                        onClick={handleClick} 
                        onChange={handleChange}
                    >
                        {this.sortOptions.map(([value, name]) => {
                            return <option value={value}>{name}</option>
                        })}
                    </select>
                </form>
            </div>
    }
}

class TagsMobileSortForm extends RoomListMobileSortForm {
    protected readonly sortOptions: SortOption[] = [
        ["", "Popular (Default)"],
        ["-vc", "Viewers (High to Low)"],
        ["vc", "Viewers (Low to High)"],
        ["-rc", "Rooms (High to Low)"],
        ["rc", "Rooms (Low to High)"],
        ["ht", "A to Z"],
        ["-ht", "Z to A"],
    ]
    protected readonly cookieName = TAG_SORT_COOKIE_NAME
    protected readonly clickPageAction = "TagsSortIconClicked"
    protected readonly changePageAction = "TagsSortIconChanged"

    protected reload(): void {
        location.reload()
    }
}

function getSortButton<T extends RoomListMobileSortForm>(type: new() => T ): HTMLElement {
    const mobileSortForm = new type()
    mobileSortForm.init()
    return mobileSortForm.element
}

export function getMobileRoomListSortButton(): HTMLElement {
    return getSortButton(RoomListMobileSortForm)
}

export function getMobileTagsSortButton(): HTMLElement {
    return getSortButton(TagsMobileSortForm)
}
